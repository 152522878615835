<template>
    <div class="pl-2 pr-2">
      <div class="rounded-md overflow-hidden relative w-full h-36 item-category" @click="clickItem()" :class="{ selectedItem : active == data.id }">
          <div class="overflow-selected"><Icon icon="akar-icons:check" /></div>
          <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
          </div>
          <img :src="data.images" class="absolute w-full h-full object-cover" alt="">
          <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-md">{{ data.title }}</div>
      </div>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'

    export default {
        name: 'card_category',
        props: ['data', 'active'],
        components: {
            Icon
        },
        methods: {
            clickItem () {
                this.$emit('click-category')
            }
        }
    }
</script>
