<template>
    <div id="group">
        <transition name="slide-fade" mode="out-in">
        <div class="mcontainer" v-if="this.$route.name === 'Group' ">
            <div class="md:flex justify-between relative md:mb-4 mb-3">
                <div class="flex-1 d-inline">
                    <h2 class="text-lg font-semibold"> Grup </h2>
                    <p>Bergabung dengan komunitas-komunitas yang beragam, produktif, dan inovatif.</p>
                    <br>
                </div>
                <b-dropdown variant="primary" class="my-2 d-inline" split split-to="/app/group/create" v-if="datauser.is_admin === 1">
                    <template #button-content>
                        <div class="flex items-center">
                            <Icon icon="uiw:user-add" class="mr-3" />
                            <span> Create Group</span>
                        </div>
                    </template>
                    <b-dropdown-item to="/app/group/reports">
                        <div class="flex items-center">
                            <Icon icon="uiw:edit" class="mr-3" />
                            <span> Report</span>
                        </div>
                    </b-dropdown-item>
                    <b-dropdown-item to="/app/group/categories">
                        <div class="flex items-center">
                            <Icon icon="uiw:edit" class="mr-3" />
                            <span> Categories</span>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>
                <router-link to="/app/group/create" v-else class="flex items-center justify-center h-9 lg:px-5 px-2 rounded-md bg-blue-600 text-white space-x-1.5 absolute right-0">
                    <span> Create Group </span>
                </router-link>
            </div>
            <div class="relative">
                <div class="px-1 py-3">
                    <b-tabs content-class="mt-4">
                        <b-tab title="My Group" active>
                            <ul class="uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid equal-cols">
                                <CardBlog v-for="my_group in my_groups" :key="my_group.id" :data="my_group" :styles="'group'" />
                            </ul>
                        </b-tab>
                        <b-tab title="Explore Group">
                            <div class="sm:my-6 my-3 flex items-center justify-between pb-3">
                                <h2 class="text-lg font-semibold"> Categories </h2>
                            </div>
                            <VueSlickCarousel v-bind="CarouselSetting" v-if="categories !== null">
                                <CardCategory v-for="category in categories" :key="category.id" :data="category" :active="activeCat" @click-category="handleSelectCategory(category)" />
                                <template #prevArrow="arrowOption">
                                <div class="custom-arrow arrow-category">
                                    <Icon icon="dashicons:arrow-left-alt2" />
                                    {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                                </div>
                                </template>
                                <template #nextArrow="arrowOption">
                                <div class="custom-arrow arrow-category">
                                    <Icon icon="dashicons:arrow-right-alt2" />
                                    {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                                </div>
                                </template>
                            </VueSlickCarousel>
                            <div class="sm:my-6 mt-5 mb-3 flex items-center justify-between pb-3">
                                <h2 class="text-lg font-semibold"> Sugestion Group </h2>
                                <div class="flex align-items-center">
                                    <div class="header_search mr-2 search-group">
                                        <i class="uil-search-alt"></i>
                                        <input v-model="filter.search" v-on:keyup.enter="fetchSuggestion" type="text" placeholder="Search group..." autocomplete="off" class="form-control">
                                    </div>
                                    <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
                                        <template #button-content>
                                            <span class="text-gray-500">Sort {{ filter.order ? `by ${filter.order}` : ''}}</span>
                                        </template>
                                        <b-dropdown-item href="#" @click.prevent="filter.order = 'name'">Name</b-dropdown-item>
                                        <b-dropdown-item href="#" @click.prevent="filter.order = 'newest'">Newest</b-dropdown-item>
                                        <b-dropdown-item href="#" @click.prevent="filter.order = 'oldest'">Oldest</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                            <ul class="uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid equal-cols" v-if="loading">
                                <CardBlog v-for="explore in explores" :key="explore.id" :data="explore" :styles="'group'" />
                            </ul>
                            <div class="row mt-5" v-if="!loading">
                                <content-placeholders v-for="index in 4" :key="index" class="col-md-3 my-3" :centered="false">
                                    <content-placeholders-img />
                                    <content-placeholders-text :lines="2" />
                                </content-placeholders>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
        <router-view/>
        </transition>
    </div>
</template>
<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import { Icon } from '@iconify/vue2'
    import CardBlog from '@/components/CardBlog'
    import { mapState } from 'vuex'
    import CardCategory from '@/components/CardCategory'
    import axios from 'axios'

    export default {
        name: 'Group',
        components: {
            CardBlog,
            VueSlickCarousel,
            Icon,
            CardCategory
        },
        computed: {
            ...mapState('auth', { datauser: 'user' })
        },
        data () {
            return {
                CarouselSetting: {
                    arrows: true,
                    dots: false,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,
                    draggable: false,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 2
                            }
                        }
                    ]
                },
                my_groups: null,
                categories: null,
                explores: null,
                loading: false,
                filter: {
                    search: '',
                    order: '',
                    category: ''
                },
                activeCat: 0
            }
        },
        created () {
            this.fetchData()
            this.fetchSuggestion()
        },
        watch: {
            $route: 'fetchData',
            'filter.order' () {
                this.fetchSuggestion()
            }
        },
        methods: {
            fetchSuggestion () {
                const $this = this
                this.loading = false
                axios.get('group', { params: this.filter }).then(function (res) {
                    $this.explores = res.data.result
                    $this.loading = true
                })
            },
            fetchData () {
                if (this.$route.name === 'Group') {
                    const $this = this
                    this.loading = false
                    axios.get('group', { params: this.filter }).then(function (res) {
                        $this.explores = res.data.result
                        $this.loading = true
                    })
                    axios.get('group/my_group').then(function (res) {
                        $this.my_groups = res.data.result
                    })
                    axios.get('category_group').then(function (res) {
                        $this.categories = res.data.result
                    })
                }
            },
            handleSelectCategory (category) {
                if (this.activeCat === category.id) {
                    this.activeCat = 0
                    this.filter.category = ''
                } else {
                    this.activeCat = category.id
                    this.filter.category = category.id
                }
                this.fetchSuggestion()
            }
        }
    }
</script>
